exports.components = {
  "component---src-node-pages-calling-abroad-country-js": () => import("./../../../src/node_pages/calling-abroad/country.js" /* webpackChunkName: "component---src-node-pages-calling-abroad-country-js" */),
  "component---src-node-pages-less-malarkey-index-js": () => import("./../../../src/node_pages/less-malarkey/index.js" /* webpackChunkName: "component---src-node-pages-less-malarkey-index-js" */),
  "component---src-node-pages-page-template-generic-page-tsx": () => import("./../../../src/node_pages/page-template/generic-page.tsx" /* webpackChunkName: "component---src-node-pages-page-template-generic-page-tsx" */),
  "component---src-node-pages-plan-details-index-tsx": () => import("./../../../src/node_pages/plan-details/index.tsx" /* webpackChunkName: "component---src-node-pages-plan-details-index-tsx" */),
  "component---src-node-pages-redirect-redirect-tsx": () => import("./../../../src/node_pages/redirect/redirect.tsx" /* webpackChunkName: "component---src-node-pages-redirect-redirect-tsx" */),
  "component---src-node-pages-roaming-europe-index-js": () => import("./../../../src/node_pages/roaming/europe/index.js" /* webpackChunkName: "component---src-node-pages-roaming-europe-index-js" */),
  "component---src-node-pages-roaming-international-index-js": () => import("./../../../src/node_pages/roaming/international/index.js" /* webpackChunkName: "component---src-node-pages-roaming-international-index-js" */),
  "component---src-node-pages-sim-in-hand-index-tsx": () => import("./../../../src/node_pages/sim-in-hand/index.tsx" /* webpackChunkName: "component---src-node-pages-sim-in-hand-index-tsx" */),
  "component---src-node-pages-social-tariff-index-tsx": () => import("./../../../src/node_pages/social-tariff/index.tsx" /* webpackChunkName: "component---src-node-pages-social-tariff-index-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-plans-tsx": () => import("./../../../src/pages/all-plans.tsx" /* webpackChunkName: "component---src-pages-all-plans-tsx" */),
  "component---src-pages-calling-abroad-countries-js": () => import("./../../../src/pages/calling-abroad/countries.js" /* webpackChunkName: "component---src-pages-calling-abroad-countries-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-coverage-checker-app-js": () => import("./../../../src/pages/coverage-checker-app.js" /* webpackChunkName: "component---src-pages-coverage-checker-app-js" */),
  "component---src-pages-coverage-checker-js": () => import("./../../../src/pages/coverage-checker.js" /* webpackChunkName: "component---src-pages-coverage-checker-js" */),
  "component---src-pages-dashboard-groups-data-only-plans-js": () => import("./../../../src/pages/dashboard/groups/data-only-plans.js" /* webpackChunkName: "component---src-pages-dashboard-groups-data-only-plans-js" */),
  "component---src-pages-dashboard-groups-data-only-sim-plan-tsx": () => import("./../../../src/pages/dashboard/groups/data-only-sim-plan.tsx" /* webpackChunkName: "component---src-pages-dashboard-groups-data-only-sim-plan-tsx" */),
  "component---src-pages-friend-js": () => import("./../../../src/pages/friend.js" /* webpackChunkName: "component---src-pages-friend-js" */),
  "component---src-pages-group-plans-js": () => import("./../../../src/pages/group-plans.js" /* webpackChunkName: "component---src-pages-group-plans-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-network-status-checker-js": () => import("./../../../src/pages/network-status-checker.js" /* webpackChunkName: "component---src-pages-network-status-checker-js" */),
  "component---src-pages-preview-page-[id]-tsx": () => import("./../../../src/pages/preview/page/[id].tsx" /* webpackChunkName: "component---src-pages-preview-page-[id]-tsx" */),
  "component---src-pages-redeem-voucher-tsx": () => import("./../../../src/pages/redeem-voucher.tsx" /* webpackChunkName: "component---src-pages-redeem-voucher-tsx" */),
  "component---src-pages-roaming-europe-js": () => import("./../../../src/pages/roaming/europe.js" /* webpackChunkName: "component---src-pages-roaming-europe-js" */),
  "component---src-pages-roaming-international-js": () => import("./../../../src/pages/roaming/international.js" /* webpackChunkName: "component---src-pages-roaming-international-js" */),
  "component---src-pages-roaming-setup-js": () => import("./../../../src/pages/roaming-setup.js" /* webpackChunkName: "component---src-pages-roaming-setup-js" */)
}

